
import React from 'react';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import ApartmentDropDownSubMenu from './ApartmentDropDownSubMenu';
import './stylesDropDown.scss';


export default function ApartmentDropDown ({ data, selectApartment }) {    
    const getLinks = () => {
        const links = [];
        
        let currKeyIndex = 0;
        
        if (Array.isArray(data.title)) {
            links.push(
                <ApartmentDropDownSubMenu options={ data.title } data={data} />
            );
        } else {
            links.push(
                <button className='residences--apartment-btn' style={ { left: data.left, top: data.top } } onClick={ () => selectApartment(data.title) }></button>
            );
        }
        return links;

        console.log(links);
    }

    return (
        getLinks()
    );

}