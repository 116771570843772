import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { closeApartment } from './';

function ViewResidencesApartment ( { residences, closeApartment } ) {
  return (
      <CSSTransition className='residences--apartment' in={ residences.isApartmentActive } timeout={ 300 } classNames='fade' unmountOnExit>
          <div>
              <img className='shared--img-fill' src={ './img/apartments/WSP-Floorplan_' + residences.tower + "_" + residences.apartment + '.jpg' } width='1920' height='1080' alt='residences level' />
              <button className='residences--apartment__close-btn' onClick={ closeApartment } />
          </div>
      </CSSTransition> 
  );

}


const mapStateToProps = (state) => ({
  residences: state.residences
})

const mapDispatchToProps = (dispatch) => ({
  closeApartment: () => dispatch(closeApartment())
})


export default connect(mapStateToProps, mapDispatchToProps)(ViewResidencesApartment)