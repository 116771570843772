const RITZ_CARLTON = 'Ritz Carlton';
const T1_SKY_RESIDENCES = 'T1 Sky Residences';
const T2_SKY_RESIDENCES = 'T2 Sky Residences';
const T3_SKY_RESIDENCES = 'T3 Sky Residences';
const T4_SKY_RESIDENCES = 'T4 Sky Residences';
const T1_CENTRAL_RESIDENCES = 'T1 Central Residences';
const T2_CENTRAL_RESIDENCES = 'T2 Central Residences';
const T3_CENTRAL_RESIDENCES = 'T3 Central Residences';
const T4_CENTRAL_RESIDENCES = 'T4 Central Residences';
const T1_GARDEN_RESIDENCES = 'T1 Garden Residences';
const T2_GARDEN_RESIDENCES = 'T2 Garden Residences';
const T4_GARDEN_RESIDENCES = 'T4 Garden Residences';
const T1_T2_AMENITIES = 'T1 & T2 Amenities';
const T3_T4_AMENITIES = 'T3 & T4 Amenities';

export const data = {
    rotationHotspots: {
        0: [
            { title: T2_SKY_RESIDENCES, left: '1300px', top: '200px' },
            { title: T2_CENTRAL_RESIDENCES, left: '1300px', top: '320px' },
            { title: T2_GARDEN_RESIDENCES, left: '1300px', top: '440px' },
            { title: T1_T2_AMENITIES, left: '1300px', top: '820px' }
        ],
        18: [
            { title: RITZ_CARLTON, left: '1300px', top: '185px' },
            { title: T1_SKY_RESIDENCES, left: '1300px', top: '310px' },
            { title: T1_CENTRAL_RESIDENCES, left: '1300px', top: '430px' },
            { title: T1_GARDEN_RESIDENCES, left: '1300px', top: '550px' },
            { title: T1_T2_AMENITIES, left: '1300px', top: '815px' }
        ],
        36: [
            { title: T4_SKY_RESIDENCES, left: '1300px', top: '265px' },
            { title: T4_CENTRAL_RESIDENCES, left: '1300px', top: '425px' },
            { title: T4_GARDEN_RESIDENCES, left: '1300px', top: '550px' },
            { title: T3_T4_AMENITIES, left: '1300px', top: '750px' }
        ],
        54: [
            { title: T3_SKY_RESIDENCES, left: '1300px', top: '395px' },
            { title: T3_CENTRAL_RESIDENCES, left: '1300px', top: '525px' },
            { title: T3_T4_AMENITIES, left: '1300px', top: '655px' }
        ]
    },
    rotationPhotoHotspots: {
        0: [
            // { title: 'queens wharf tower', left: '1062px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
        ],
        18: [
            // { title: 'queens wharf tower', left: '1190px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
        ],
        36: [
            // { title: 'queens wharf tower', left: '810px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
        ],
        54: [
            // { title: 'queens wharf tower', left: '750px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },},
        ]
    },
    levelGroups: {
        'Ritz Carlton': [{ level: '63-79', target: 'T1_63-79', inactive: true }],       
        'T1 Sky Residences': [ { level: '36-60', target: 'T1_36-60' }],
        'T1 Central Residences': [ { level: '12-35', target: 'T1_12-35' }],
        'T1 Garden Residences': [ { level: '10', target: 'T1_10' }, { level: '9', target: 'T1_9' }, { level: '8', target: 'T1_8' },  { level: '7', target: 'T1_7' }, { level: '6', target: 'T1_6' }],
        'T1 & T2 Amenities': [ { level: '6, 7 & 11', target: 'T2_6, 7 & 11', inactive: true }],    
        'T2 Sky Residences': [{ level: '39 & 41-63', target: 'T2_39_41-63' }],
        'T2 Central Residences': [ { level: '12-38', target: 'T2_12-38' }],
        //'T2 Garden Residences': [{level:'10', target: 'T2_10'}, {level:'8', target: 'T2_8'}, {level:'7', target: 'T2_7'}, {level:'6', target: 'T2_6'}, {level:'3', target: 'T2_3'}, {level:'2', target: 'T2_2'}, {level:'1', target: 'T2_1'} ],
        'T2 Garden Residences': [{level:'10', target: 'T2_10'}, {level:'9', target: 'T2_9'}, {level:'8', target: 'T2_8'}, {level:'7', target: 'T2_7'}, {level:'6', target: 'T2_6'}, {level:'3', target: 'T2_3'} , {level:'2', target: 'T2_2'}, {level:'1', target: 'T2_1'} ],
        'T3 Sky Residences': [ { level: '43 & 45-68', target: 'T3_43_45-68' }],
        'T3 Central Residences': [ { level: '11-42', target: 'T3_11-42' }],
        'T3 & T4 Amenities': [ { level: '9 & 10', target: 'T3_9 & 10', inactive: true }],
        'T4 Sky Residences': [ { level: '42 & 44-70', target: 'T4_42_44-70' }],
        'T4 Central Residences': [ { level: '11-41', target: 'T4_11-41' }],
        'T4 Garden Residences': [ { level: '10', target: 'T4_10' }, { level: '6-8', target: 'T4_6-8' }, { level: '3-5', target: 'T4_3-5' }],
    },
    rotationOverlays: {
        0: {
            'T2 Sky Residences': { src: './img/residences/3D_Floorplans_Highlights/Frame_00_T2_SkyResidences.png', top: 0, left: 0 },
            'T2 Central Residences': { src: './img/residences/3D_Floorplans_Highlights/Frame_00_T2_CentralResidences.png', top: 0, left: 0 },
            'T2 Garden Residences': { src: './img/residences/3D_Floorplans_Highlights/Frame_00_T2_GardenResidences.png', top: 0, left: 0 },
            'T1 & T2 Amenities': { src: './img/residences/3D_Floorplans_Highlights/Frame_00_T1+2_Amenities.png', top: 0, left: 0 },
        },
        18: {
            'Ritz Carlton': { src: './img/residences/3D_Floorplans_Highlights/Frame_18_T1_The-Ritz-Carlton.png', top: 0, left: 0 },
            'T1 Sky Residences': { src: './img/residences/3D_Floorplans_Highlights/Frame_18_T1_SkyResidences.png', top: 0, left: 0 },
            'T1 Central Residences': { src: './img/residences/3D_Floorplans_Highlights/Frame_18_T1_CentralResidences.png', top: 0, left: 0 },
            'T1 Garden Residences': { src: './img/residences/3D_Floorplans_Highlights/Frame_18_T1_GardenResidences.png', top: 0, left: 0 },
            'T1 & T2 Amenities': { src: './img/residences/3D_Floorplans_Highlights/Frame_18_T1+2_Amenities.png', top: 0, left: 0 },
        },
        36: {
            'T4 Sky Residences': { src: './img/residences/3D_Floorplans_Highlights/Frame_36_T4_SkyResidences.png', top: 0, left: 0 },
            'T4 Central Residences': { src: './img/residences/3D_Floorplans_Highlights/Frame_36_T4_CentralResidences.png', top: 0, left: 0 },
            'T4 Garden Residences': { src: './img/residences/3D_Floorplans_Highlights/Frame_36_T4_GardenResidences.png', top: 0, left: 0 },
            'T3 & T4 Amenities': { src: './img/residences/3D_Floorplans_Highlights/Frame_36_T3+4_Amenities.png', top: 0, left: 0 },
        },
        54: {
            'T3 Sky Residences': { src: './img/residences/3D_Floorplans_Highlights/Frame_54_T3_SkyResidences.png', top: 0, left: 0 },
            'T3 Central Residences': { src: './img/residences/3D_Floorplans_Highlights/Frame_54_T3_CentralResidences.png', top: 0, left: 0 },
            'T3 & T4 Amenities': { src: './img/residences/3D_Floorplans_Highlights/Frame_54_T3+4_Amenities.png', top: 0, left: 0 },
        },
    },
    levelGroupApartments: {
        'T1_36-60': [
            { title:'A5c', left: '609px', top: '595px', tower: 'T1' },
            { title:'C5c', left: '591px', top: '405px', tower: 'T1' },
            { title:'A4c', left: '617px', top: '229px', tower: 'T1' },
            { title:'B7c', left: '825px', top: '221px', tower: 'T1' },
            { title:'C8c', left: '965px', top: '263px', tower: 'T1' },
            { title:'C9c', left: '1063px', top: '321px', tower: 'T1' },
            { title:'C10c', left: '1167px', top: '377px', tower: 'T1' },
            { title:'B2c', left: '1323px', top: '471px', tower: 'T1' },
            { title:[ 'B3a' , 'B9b' , 'B13c' ], left: '1235px', top: '629px', tower: 'T1' },
            { title:[ 'B4a' ,  'B10b' , 'B14c' ], left: '1141px', top: '767px', tower: 'T1' },
            { title:[ 'B5c' , 'B11b' ], left: '933px', top: '755px', tower: 'T1' },
            { title:[ 'B6a' , 'B12b' , 'B15c' ], left: '779px', top: '683px', tower: 'T1' },
        ],
        'T1_12-35': [
            { title: [ 'C1a' , 'C5c'], left: '580px', top: '406px', tower: 'T1' },
            { title: [ 'A2a' , 'A4c'], left: '622px', top: '238px', tower: 'T1' },
            { title: [ 'B1a', 'B7c' ], left: '830px', top: '222px', tower: 'T1' },
            { title: [ 'C3a', 'C9c' ], left: '1070px', top: '324px', tower: 'T1' },
            { title: 'C10c', left: '1170px', top: '374px', tower: 'T1' },
            { title: [ 'B2c' ,'B8b' ], left: '1320px', top: '470px', tower: 'T1' },
            { title: [ 'B4a', 'B10b' ], left: '1140px', top: '776px', tower: 'T1' },
            { title: [ 'B5c', 'B11b' ], left: '936px', top: '756px', tower: 'T1' },
            { title: [ 'B6a', 'B12b' , 'B15c' ], left: '780px', top: '692px', tower: 'T1' },
        ],
        'T1_6': [
            { title: 'X01', left: '1163px', top: '668px', tower: 'T1' },
            { title: 'X03', left: '964px', top: '837px', tower: 'T1' },
            { title: 'X05', left: '862px', top: '837px', tower: 'T1' },
            { title: 'Y10', left: '756px', top: '742px', tower: 'T1' },
        ],
        'T1_7': [
            { title: 'Y15', left: '704px', top: '672px', tower: 'T1' },
            { title: 'X13', left: '1094px', top: '676px', tower: 'T1' },
            { title: 'Y17', left: '1094px', top: '742px', tower: 'T1' },
            { title: 'Y19', left: '981px', top: '846px', tower: 'T1' },
            { title: 'X17', left: '810px', top: '846px', tower: 'T1' },
            { title: 'X19', left: '698px', top: '846px', tower: 'T1' },
            { title: 'Y20', left: '704px', top: '753px', tower: 'T1' },
        ],
        'T1_8': [
            { title:'X32', left: '802px', top: '500px', tower: 'T1' },
            { title:'Y25', left: '891px', top: '509px', tower: 'T1' },
            { title:'Y26', left: '1042px', top: '632px', tower: 'T1' },
            { title:'Y27', left: '1107px', top: '688px', tower: 'T1' },
            { title:'X35', left: '1095px', top: '776px', tower: 'T1' },
            { title:'X36', left: '999px', top: '844px', tower: 'T1' },
        ],
        'T1_9': [
            { title:'Y36', left: '804px', top: '452px', tower: 'T1' },
            { title:'Y37', left: '898px', top: '452px', tower: 'T1' },
            { title:'X51', left: '978px', top: '597px', tower: 'T1' },
            { title:'Y39', left: '1039px', top: '634px', tower: 'T1' },
            { title:'Y40', left: '1115px', top: '684px', tower: 'T1' },
            { title:'X54', left: '998px', top: '839px', tower: 'T1' },
            { title:'X55', left: '940px', top: '839px', tower: 'T1' },
            { title:'X56', left: '881px', top: '839px', tower: 'T1' },
        ],
        'T1_10': [
            { title:'Y45', left: '730px', top: '611px', tower: 'T1' },
            { title:'Y47', left: '816px', top: '390px', tower: 'T1' },
            { title:'X64', left: '889px', top: '560px', tower: 'T1' },
            { title:'X66', left: '1014px', top: '629px', tower: 'T1' },
            { title:'Y48', left: '1094px', top: '673px', tower: 'T1' },
            { title:'X68', left: '1027px', top: '847px', tower: 'T1' },
        ],
        'T2_39_41-63': [
            { title:'N3c', left: '1136px', top: '315px', tower: 'T2' },
            { title:[ 'N2a' , 'N4c'], left: '1286px', top: '367px', tower: 'T2' },
            { title:[ 'O1a' , 'O7c' ], left: '1271px', top: '502px', tower: 'T2' },
            { title:[ 'O2a' , 'O8c' ], left: '1251px', top: '630px', tower: 'T2' },
            { title:[ 'O3b' , 'O9b' , 'O9c' ], left: '1107px', top: '702px', tower: 'T2' },
            { title:[ 'P1b' , 'P5a' , 'P5c' ], left: '955px', top: '702px', tower: 'T2' },
            { title:'P6c', left: '813px', top: '702px', tower: 'T2' },
            { title:[ 'M6b' ,  'M11c' ], left: '791px', top: '316px', tower: 'T2' },
            { title:[ 'M7b' ,  'M12c' ], left: '957px', top: '316px', tower: 'T2' },
        ],
        'T2_12-38': [
            { title:'N1a', left: '1131px', top: '316px', tower: 'T2' },
            { title:'N2a', left: '1283px', top: '372px', tower: 'T2' },
            { title:'O1a', left: '1281px', top: '504px', tower: 'T2' },
            { title:'O2a', left: '1241px', top: '628px', tower: 'T2' },
            { title:[ 'O3a', 'O3b' ], left: '1105px', top: '716px', tower: 'T2' },
            { title:'P1b', left: '953px', top: '716px', tower: 'T2' },
            { title:[ 'P2a', 'P2b' ], left: '809px', top: '716px', tower: 'T2' },
            { title:'M2a', left: '455px', top: '508px', tower: 'T2' },
            { title:[ 'M4a', 'M6b' ], left: '785px', top: '314px', tower: 'T2' },
            { title:[ 'M5a', 'M7b' ], left: '953px', top: '314px', tower: 'T2' },
        ],

        'T2_1': [
            { title:'Y02', left: '1179px', top: '243px', tower: 'T2' },
        ],
        'T2_2': [
            { title:'Y01', left: '1109px', top: '188px', tower: 'T2' },
            { title:'Y04', left: '1173px', top: '239px', tower: 'T2' },
        ],
        'T2_3': [
            { title:'Y01', left: '1093px', top: '200px', tower: 'T2' },
            { title:'Y04', left: '1157px', top: '251px', tower: 'T2' },
        ],

        'T2_6': [
            { title: 'X08', left: '750px', top: '270px', tower: 'T2' },
            { title: 'X09', left: '750px', top: '215px', tower: 'T2' },
            { title: 'Y11', left: '840px', top: '100px', tower: 'T2' },
            { title: 'Y14', left: '1135px', top: '190px', tower: 'T2' },
        ],
        'T2_7': [
            { title: 'X20', left: '710px', top: '272px', tower: 'T2' },
            { title: 'X21', left: '710px', top: '216px', tower: 'T2' },
            { title: 'X22', left: '710px', top: '162px', tower: 'T2' },
            { title: 'Y21', left: '790px', top: '108px', tower: 'T2' },
            { title: 'Y23', left: '945px', top: '108px', tower: 'T2' },
        ],
        'T2_8': [
            { title: 'Z01', left: '936px', top: '414px', tower: 'T2' },
            { title: 'Y30', left: '807px', top: '357px', tower: 'T2' },
            { title: 'X76', left: '717px', top: '244px', tower: 'T2' },
            { title: 'X79', left: '1110px', top: '200px', tower: 'T2' },
            { title: 'Y35', left: '1110px', top: '267px', tower: 'T2' },
        ],
        'T2_9': [
            { title:'Y43', left: '909px', top: '382px', tower: 'T2' },
            { title:'X81', left: '727px', top: '242px', tower: 'T2' },
            { title:'X82', left: '704px', top: '187px', tower: 'T2' },
            { title:'X83', left: '715px', top: '119px', tower: 'T2' },
            { title:'Y56', left: '801px', top: '119px', tower: 'T2' },
            { title:'Y57', left: '877px', top: '119px', tower: 'T2' },
            { title:'Y59', left: '1028px', top: '148px', tower: 'T2' },
            { title:'X59', left: '1107px', top: '192px', tower: 'T2' },
        ],
        'T2_10': [
            { title:'X72', left: '819px', top: '310px', tower: 'T2' },
            { title:'X73', left: '1056px', top: '309px', tower: 'T2' },
            { title:'Y50', left: '1118px', top: '219px', tower: 'T2' },
        ],
        'T3_43_45-68': [
            { title: [ 'C1c' , 'C1g' , 'C1j' ], left: '690px', top: '619px', tower: 'T3' },
            { title: [ 'C2c' , 'C2j' ], left: '557px', top: '537px', tower: 'T3' },
            { title: [ 'C3c' , 'C3g' , 'C3j' ], left: '667px', top: '371px', tower: 'T3' },
            { title: [ 'C4c' , 'C4g' , 'C4j' ], left: '790px', top: '247px', tower: 'T3' },
            { title: [ 'C5b' , 'C5c' ], left: '991px', top: '312px', tower: 'T3' },
            { title: [ 'C6b' , 'C6c' ], left: '1141px', top: '401px', tower: 'T3' },
            { title: [ 'C7a' , 'C7c' ], left: '1288px', top: '472px', tower: 'T3' },
            { title: [ 'C8a' , 'C8c' ], left: '1300px', top: '647px', tower: 'T3' },
            { title: 'C9c', left: '1288px', top: '823px', tower: 'T3' },
            { title: [ 'C10a' ,'C10b' , 'C10c' ], left: '1083px', top: '824px', tower: 'T3' },
            { title: [ 'C11b' , 'C11c' ], left: '932px', top: '760px', tower: 'T3' },
            { title: [ 'C12b' , 'C12c' ], left: '812px', top: '687px', tower: 'T3' },
        ],
        'T3_11-42': [
            { title: [ 'C1a' , 'C1b' , 'C1c' ], left: '690px', top: '619px', tower: 'T3' },
            { title:'C2c', left: '557px', top: '537px', tower: 'T3' },
            { title: [ 'C3a' , 'C3c' ], left: '652px', top: '373px', tower: 'T3' },
            { title: [ 'C4a' , 'C4c' ], left: '790px', top: '247px', tower: 'T3' },
            { title: [ 'C5a' , 'C5b' , 'C5c' ], left: '991px', top: '312px', tower: 'T3' },
            { title: [ 'C6a' , 'C6b' , 'C6c' ], left: '1146px', top: '407px', tower: 'T3' },
            { title: [ 'C7a' , 'C7c' ], left: '1295px', top: '483px', tower: 'T3' },
            { title: [ 'C8a' , 'C8c' ], left: '1299px', top: '653px', tower: 'T3' },
            { title: 'C9c', left: '1277px', top: '830px', tower: 'T3' },
            { title: [ 'C10a' , 'C10b' , 'C10c' ], left: '1083px', top: '824px', tower: 'T3' },
            { title: [ 'C11a' , 'C11b' , 'C11c' ], left: '932px', top: '760px', tower: 'T3' },
            { title: [ 'C12a' , 'C12b' , 'C12c' ], left: '812px', top: '687px', tower: 'T3' },
        ],
        'T4_42_44-70': [
            { title: [ 'D1c' , 'D1g' , 'D1j' ], left: '679px', top: '640px', tower: 'T4' },
            { title:'D2c', left: '558px', top: '548px', tower: 'T4' },
            { title: [ 'D3a' , 'D3b' , 'D3c' ], left: '646px', top: '383px', tower: 'T4' },
            { title: [ 'D4a' , 'D4c' ], left: '749px', top: '285px', tower: 'T4' },
            { title: [ 'D5a' , 'D5b' , 'D5c' ], left: '909px', top: '307px', tower: 'T4' },
            { title:'D6c', left: '1036px', top: '307px', tower: 'T4' },
            { title: [ 'D7a' , 'D7b' , 'D7c' ], left: '1165px', top: '307px', tower: 'T4' },
            { title:'D8c', left: '1338px', top: '393px', tower: 'T4' },
            { title: [ 'D9a' , 'D9b' , 'D9c' ], left: '1339px', top: '544px', tower: 'T4' },
            { title:'D10c', left: '1358px', top: '705px', tower: 'T4' },
            { title: [ 'D11a' , 'D11b' , 'D11c' ], left: '1157px', top: '664px', tower: 'T4' },
            { title: [ 'D12a' , 'D12b' , 'D12c' ], left: '1016px', top: '665px', tower: 'T4' },
            { title: [ 'D13c' , 'D13g' , 'D13h' , 'D13j' ], left: '847px', top: '665px', tower: 'T4' },
        ],
        'T4_11-41': [
            { title: [ 'D1a' , 'D1b' , 'D1c' ], left: '648px', top: '637px', tower: 'T4' },
            { title:'D2c', left: '527px', top: '545px', tower: 'T4' },
            { title: [ 'D3a' , 'D3b' , 'D3c' ], left: '615px', top: '380px', tower: 'T4' },
            { title: [ 'D4a' , 'D4c' ], left: '718px', top: '282px', tower: 'T4' },
            { title: [ 'D5a' , 'D5b' , 'D5c' ], left: '878px', top: '304px', tower: 'T4' },
            { title: [ 'D6a' , 'D6b' , 'D6c' ], left: '1005px', top: '304px', tower: 'T4' },
            { title: [ 'D7a' , 'D7b' , 'D7c' ], left: '1134px', top: '304px', tower: 'T4' },
            { title:'D8c', left: '1307px', top: '390px', tower: 'T4' },
            { title: [ 'D9a' , 'D9b' , 'D9c' ], left: '1308px', top: '541px', tower: 'T4' },
            { title:'D10c', left: '1358px', top: '705px', tower: 'T4' },
            { title: [ 'D11a' , 'D11b' , 'D11c' ], left: '1126px', top: '661px', tower: 'T4' },
            { title: [ 'D12a' , 'D12b' , 'D12c' ], left: '985px', top: '662px', tower: 'T4' },
            { title: [ 'D13a' , 'D13b' , 'D13c' ], left: '816px', top: '662px', tower: 'T4' },
        ],
        'T4_10': [
            { title:'D101p', left: '817px', top: '898px', tower: 'T4' },
            { title:'D102p', left: '758px', top: '826px', tower: 'T4' },
            { title:'D103p', left: '1080px', top: '908px', tower: 'T4' },
            { title:'D104p', left: '996px', top: '908px', tower: 'T4' },
            { title:'D105p', left: '905px', top: '908px', tower: 'T4' },
        ],
        'T4_6-8': [
            { title:'D71p', left: '817px', top: '898px', tower: 'T4' },
            { title:'D72p', left: '733px', top: '897px', tower: 'T4' },
            { title:'D73p', left: '756px', top: '788px', tower: 'T4' },
            { title:'D74p', left: '792px', top: '712px', tower: 'T4' },
            { title:'D75p', left: '1180px', top: '764px', tower: 'T4' },
            { title:'D76p', left: '1174px', top: '828px', tower: 'T4' },
            { title:'D77p', left: '1158px', top: '898px', tower: 'T4' },
            { title:'D78p', left: '1045px', top: '898px', tower: 'T4' },
            { title:'D79p', left: '974px', top: '898px', tower: 'T4' },
            { title:'D710p', left: '896px', top: '898px', tower: 'T4' },
        ],
        'T4_3-5': [
            { title:'D51p', left: '816px', top: '910px', tower: 'T4' },
            { title:'D52p', left: '733px', top: '897px', tower: 'T4' },
            { title:'D53p', left: '756px', top: '798px', tower: 'T4' },
            { title:'D54p', left: '788px', top: '713px', tower: 'T4' },
            { title:'D55p', left: '1201px', top: '760px', tower: 'T4' },
            { title:'D56p', left: '1216px', top: '826px', tower: 'T4' },
            { title:'D57p', left: '1208px', top: '910px', tower: 'T4' },
            { title:'D58p', left: '1120px', top: '910px', tower: 'T4' },
            { title:'D59p', left: '1043px', top: '910px', tower: 'T4' },
            { title:'D510p', left: '975px', top: '910px', tower: 'T4' },
            { title:'D511p', left: '895px', top: '910px', tower: 'T4' },
        ],
    },
    apartmentTypes: {
        'E3AS.1':[
            { title: '1602', viewSrc: 'Lv17-360-Morning-E' },
        ],  
    }
}
