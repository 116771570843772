import React from 'react'
import { connect } from 'react-redux';
import { selectLevel } from './';


function ConnectButtonAmenitiesLevel ( { levelData, currLevel, selectLevel } ) {
  
    return (
        <button 
            className={ levelData.title === currLevel.title ? 'amenities--ui__btn-active' : 'amenities--ui__btn' } 
            onClick={ () => selectLevel(levelData) } 
        >
            { levelData.title }
        </button>
    );

}


const mapStateToProps = (state) => ({
    currLevel: state.amenities.currLevel
});

const mapDispatchToProps = (dispatch) => ({
    selectLevel: (currLevel) => dispatch(selectLevel(currLevel))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectButtonAmenitiesLevel);