
export const data = [{
    id: 0,
    levels: [
        { 
            title: 'Level 11', 
            src: './img/amenities/bkg--T1&T2-amenities-L11.jpg',
            index: 0,
            hotSpots: [
                { title: 'L11-garden-lounge', left: '803px', top: '863px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L11-garden-lounge-tmb.jpg', href: './img/amenities/T1&T2-amenities-L11-garden-lounge-enlarge.jpg' },
                { title: 'L11-garden-lounge-kitchen', left: '755px', top: '979px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L11-garden-lounge-kitchen-tmb.jpg', href: './img/amenities/T1&T2-amenities-L11-garden-lounge-kitchen-enlarge.jpg' },
                { title: 'L11-heated-pool', left: '746px', top: '308px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L11-heated-pool-tmb.jpg', href: './img/amenities/T1&T2-amenities-L11-heated-pool-enlarge.jpg' },
                { title: 'L11-mahjong-room', left: '1010px', top: '887px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L11-mahjong-room-tmb.jpg', href: './img/amenities/T1&T2-amenities-L11-mahjong-room-enlarge.jpg' },
                { title: 'L11-welness-centre', left: '1286px', top: '300px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L11-welness-centre-tmb.jpg', href: './img/amenities/T1&T2-amenities-L11-welness-centre-enlarge.jpg' },
            ]
        },
        { 
            title: 'Level 7', 
            src: './img/amenities/bkg--T1&T2-amenities-L7.jpg',
            index: 1,
            hotSpots: [
                { title: 'L7-boardroom', left: '1031px', top: '285px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L7-boardroom-tmb.jpg', href: './img/amenities/T1&T2-amenities-L7-boardroom-enlarge.jpg' },
                { title: 'L7-library', left: '837px', top: '283px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L7-library-tmb.jpg', href: './img/amenities/T1&T2-amenities-L7-library-enlarge.jpg' },
                { title: 'L7-private-dining', left: '1346px', top: '316px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L7-private-dining-tmb.jpg', href: './img/amenities/T1&T2-amenities-L7-private-dining-enlarge.jpg' },
                { title: 'L7-members-only-club-lounge', left: '860px', top: '700px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L7-members-only-club-lounge-tmb.jpg', href: './img/amenities/T1&T2-amenities-L7-members-only-club-lounge-enlarge.jpg' },
            ]
        },
        { 
            title: 'Level 6', 
            src: './img/amenities/bkg--T1&T2-amenities-L6.jpg',
            index: 2,
            hotSpots: [
                { title: 'L6-breakout-seating-fireplace', left: '940px', top: '583px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-breakout-seating-fireplace-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-breakout-seating-fireplace-enlarge.jpg' },
                { title: 'L6-feature-staircase', left: '1166px', top: '700px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-feature-staircase-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-feature-staircase-enlarge.jpg' },
                { title: 'L6-heated-lap-pool', left: '998px', top: '447px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-heated-lap-pool-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-heated-lap-pool-enlarge.jpg' },
                { title: 'L6-private-cinema', left: '1026px', top: '755px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-private-cinema-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-private-cinema-enlarge.jpg' },
                { title: 'L6-private-dining-room', left: '931px', top: '853px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-private-dining-room-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-private-dining-room-enlarge.jpg' },
                { title: 'L6-private-karaoke-room', left: '931px', top: '853px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-private-karaoke-room-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-private-karaoke-room-enlarge.jpg' },
                { title: 'L6-sauna', left: '1028px', top: '327px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-sauna-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-sauna-enlarge.jpg' },
                { title: 'L6-gym', left: '1450px', top: '327px', align: 'top-right', src: './img/amenities/T1&T2-amenities-L6-gym-tmb.jpg', href: './img/amenities/T1&T2-amenities-L6-gym-enlarge.jpg' },
            ]        
            },
        { 
            title: 'Ground', 
            src: './img/amenities/bkg--T1&T2-amenities-G.jpg',
            index: 3,
            hotSpots: [
                { title:'G-ritz-foyer', left: '968px', top: '873px', align: 'top-right', src: './img/amenities/T1&T2-amenities-G-ritz-foyer-tmb.jpg', href: './img/amenities/T1&T2-amenities-G-ritz-foyer-enlarge.jpg' },
                { title:'G-ritz-port-cochere', left: '1084px', top: '749px', src: './img/amenities/T1&T2-amenities-G-ritz-port-cochere-tmb.jpg', href: './img/amenities/T1&T2-amenities-G-ritz-port-cochere-enlarge.jpg' },
                { title:'G-tower-1-lobby', left: '827px', top: '697px', align: 'top-right', src: './img/amenities/T1&T2-amenities-G-tower-1-lobby-tmb.jpg', href: './img/amenities/T1&T2-amenities-G-tower-1-lobby-enlarge.jpg' },
                { title:'G-tower-2-lobby', left: '1031px', top: '406px', align: 'top-right', src: './img/amenities/T1&T2-amenities-G-tower-2-lobby-tmb.jpg', href: './img/amenities/T1&T2-amenities-G-tower-2-lobby-enlarge.jpg' },
            ]
        },
    ]
},
{
    id: 1,
    levels: [
        { 
            title: 'Level 10', 
            src: './img/amenities/bkg--T3&T4-amenities-L10.jpg',
            index: 0,
            hotSpots: [
                { title: 'L10-movement-studio', left: '1089px', top: '436px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-movement-studio-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-movement-studio-enlarge.jpg' },
                { title: 'L10-plunge-pool-courtyard', left: '1134px', top: '612px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-plunge-pool-courtyard-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-plunge-pool-courtyard-enlarge.jpg' },
                { title: 'L10-private-lounge', left: '761px', top: '288px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-private-lounge-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-private-lounge-enlarge.jpg' },
                { title: 'L10-screening-room', left: '843px', top: '236px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-screening-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-screening-room-enlarge.jpg' },
                { title: 'L10-vip-wine-tasting-room', left: '845px', top: '156px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-vip-wine-tasting-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-vip-wine-tasting-room-enlarge.jpg' },
                { title: 'L10-boardroom', left: '727px', top: '733px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-boardroom-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-boardroom-enlarge.jpg' },
                { title: 'L10-executive-lounge', left: '922px', top: '762px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-executive-lounge-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-executive-lounge-enlarge.jpg' },
                { title: 'L10-chefs-room-lounge', left: '1006px', top: '467px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-chefs-room-lounge-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-chefs-room-lounge-enlarge.jpg' },
                { title: 'L10-chefs-room-kitchen', left: '981px', top: '400px', src: './img/amenities/T3&T4-amenities-L10-chefs-room-kitchen-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-chefs-room-kitchen-enlarge.jpg' },
                { title: 'L10-cocktail-lounge', left: '863px', top: '341px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-cocktail-lounge-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-cocktail-lounge-enlarge.jpg' },
                { title: 'L10-landscaped-courtyard', left: '799px', top: '640px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-landscaped-courtyard-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-landscaped-courtyard-enlarge.jpg' },
                { title: 'L10-bar', left: '982px', top: '737px', align: 'bot-right', src: './img/amenities/T3&T4-amenities-L10-bar-tmb.jpg', href: './img/amenities/T3&T4-amenities-L10-bar-enlarge.jpg' },
            ]
        },
        { 
            title: 'Level 9', 
            src: './img/amenities/bkg--T3&T4-amenities-L9.jpg',
            index: 1,
            hotSpots: [
                { title: 'L9-billiards', left: '1067px', top: '592px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-billiards-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-billiards-enlarge.jpg' },
                { title: 'L9-cinema', left: '1070px', top: '731px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-cinema-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-cinema-enlarge.jpg' },
                { title: 'L9-function-room', left: '698px', top: '867px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-function-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-function-room-enlarge.jpg' },
                { title: 'L9-games-room', left: '1067px', top: '683px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-games-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-games-room-enlarge.jpg' },
                { title: 'L9-garden-lounge', left: '1070px', top: '295px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-garden-lounge-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-garden-lounge-enlarge.jpg' },
                { title: 'L9-gymnasium', left: '836px', top: '275px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-gymnasium-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-gymnasium-enlarge.jpg' },
                { title: 'L9-karaoke', left: '1120px', top: '602px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-karaoke-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-karaoke-enlarge.jpg' },
                { title: 'L9-lap-pool', left: '808px', top: '553px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-lap-pool-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-lap-pool-enlarge.jpg' },
                { title: 'L9-private-dining-room-1', left: '780px', top: '923px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-private-dining-room-1-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-private-dining-room-1-enlarge.jpg' },
                { title: 'L9-private-dining-room-2', left: '842px', top: '922px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-private-dining-room-2-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-private-dining-room-2-enlarge.jpg' },
                { title: 'L9-residential-lounge', left: '980px', top: '775px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-residential-lounge-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-residential-lounge-enlarge.jpg' },
                { title: 'L9-study-reading-room', left: '1065px', top: '900px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-study-reading-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-study-reading-room-enlarge.jpg' },
                { title: 'L9-vip-styling-room', left: '1046px', top: '823px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-vip-styling-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-vip-styling-room-enlarge.jpg' },
                { title: 'L9-virtual-golf', left: '1067px', top: '549px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-virtual-golf-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-virtual-golf-enlarge.jpg' },
                { title: 'L9-wine-tasting-room', left: '926px', top: '938px', align: 'top-right', src: './img/amenities/T3&T4-amenities-L9-wine-tasting-room-tmb.jpg', href: './img/amenities/T3&T4-amenities-L9-wine-tasting-room-enlarge.jpg' },

            ]
        },
        { 
            title: 'Ground', 
            src: './img/amenities/bkg--T3&T4-amenities-G.jpg',
            index: 2,
            hotSpots: [
                { title: 'G-dorsett-reception', left: '1106px', top: '402px', align: 'top-right', src: './img/amenities/T3&T4-amenities-G-dorsett-reception-tmb.jpg', href: './img/amenities/T3&T4-amenities-G-dorsett-reception-enlarge.jpg' },
                { title: 'G-tower-3-lobby', left: '855px', top: '330px', align: 'top-right', src: './img/amenities/T3&T4-amenities-G-tower-3-lobby-tmb.jpg', href: './img/amenities/T3&T4-amenities-G-tower-3-lobby-enlarge.jpg' },
                { title: 'G-tower-4-lobby', left: '781px', top: '815px', align: 'top-right', src: './img/amenities/T3&T4-amenities-G-tower-4-lobby-tmb.jpg', href: './img/amenities/T3&T4-amenities-G-tower-4-lobby-enlarge.jpg' },
            ]
        },
    ]
}];