import React, { useEffect, useState } from 'react';
import './accordian.scss';
import AccordianSubItem from './AccordianSubItem';

const AccordianItem = ({ faq, onToggle, active, onUrlChange }) => {
    const { question, answers } = faq;
    const [selected, setSelected] = useState(0);
    useEffect(() => {
        if (active) handleSelect(answers[0].url, 0);
    }, []);
    const reset = (url) => {
        setSelected(0);
        onUrlChange(url);
    };

    const handleSelect = (url, index) => {
        setSelected(index);
        onUrlChange(url);
    }
    return (
        <>
            <li className={`accordion_item ${active ? "active" : ""}`}>
                <button onClick={() => { 
                    onToggle(); reset(answers[0].url); 
                    }
                } className={active ? "active-text" : "inactive-text"}>
                {question}
                </button>
            </li>
            <ul style={{ marginLeft: '-25px', marginBottom: '35px' }}>
                { answers.map((answer, index) => {
                    return (
                        <AccordianSubItem key={index} index={index} answer={answer} handleSelect={handleSelect} selected={selected} active={active} />
                )})}
            </ul>
        </>
    );
    };
export default AccordianItem;