
export const data = {
    rotationHotspots: {
        0: [
            { title: 'melbourne-cricket-ground', left: '670px', top: '140px', src: './img/location-aerial/melbourne-cricket-ground-tmb.jpg', href: './img/location-aerial/melbourne-cricket-ground.jpg' },
            { title: 'aami-park', left: '860px', top: '110px', src: './img/location-aerial/aami-park-tmb.jpg', href: './img/location-aerial/aami-park.jpg' },
            { title: 'botanical-gardens', left: '1000px', top: '150px', src: './img/location-aerial/botanical-gardens-tmb.jpg', href: './img/location-aerial/botanical-gardens.jpg' },
            { title: 'melbourne-exhibition-centre', left: '1550px', top: '430px', src: './img/location-aerial/melbourne-exhibition-centre-tmb.jpg', href: './img/location-aerial/melbourne-exhibition-centre.jpg' },
            { title: 'arts-centre', left: '960px', top: '250px', src: './img/location-aerial/arts-centre-tmb.jpg', href: './img/location-aerial/arts-centre.jpg' },
            { title: 'china-town', left: '490px', top: '350px', src: './img/location-aerial/china-town-tmb.jpg', href: './img/location-aerial/china-town.jpg' },
            { title: 'collins-street-precinct', left: '1060px', top: '440px', src: './img/location-aerial/collins-street-precinct-tmb.jpg', href: './img/location-aerial/collins-street-precinct.jpg' },
            { title: 'crown-casino', left: '1220px', top: '370px', src: './img/location-aerial/crown-casino-tmb.jpg', href: './img/location-aerial/crown-casino.jpg' },
            { title: 'docklands', left: '1480px', top: '850px', src: './img/location-aerial/docklands-tmb.jpg', href: './img/location-aerial/docklands.jpg' },
            { title: 'federation-square', left: '780px', top: '290px', src: './img/location-aerial/federation-square-tmb.jpg', href: './img/location-aerial/federation-square.jpg' },
            { title: 'flagstaff-gardens', left: '630px', top: '610px', src: './img/location-aerial/flagstaff-gardens-tmb.jpg', href: './img/location-aerial/flagstaff-gardens.jpg' },
            { title: 'marvel-stadium', left: '1285px', top: '730px', src: './img/location-aerial/marvel-stadium-tmb.jpg', href: './img/location-aerial/marvel-stadium.jpg' },
            { title: 'melbourne-central', left: '480px', top: '430px', src: './img/location-aerial/melbourne-central-tmb.jpg', href: './img/location-aerial/melbourne-central.jpg' },
            { title: 'queen-victoria-market', left: '320px', top: '620px', src: './img/location-aerial/queen-victoria-market-tmb.jpg', href: './img/location-aerial/queen-victoria-market.jpg' },
            { title: 'southbank', left: '1300px', top: '400px', src: './img/location-aerial/southbank-tmb.jpg', href: './img/location-aerial/southbank.jpg' },
            { title: 'southern-cross-station', left: '1170px', top: '560px', src: './img/location-aerial/southern-cross-station-tmb.jpg', href: './img/location-aerial/southern-cross-station.jpg' },
        ]
    }
}