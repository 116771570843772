
export const data = [
    { 
        title: 'Transport',
        options: [
            { title: 'Train' },
            { title: 'Tram' },
            { title: 'Bus' },
        ]
    },
    { 
        title: 'Amenities',
        options: [
            { title: 'Sports & Recreation' },
            { title: 'Shopping' },
            { title: 'Entertainment & Culture' },
            { title: 'Education' },
            { title: 'Dining' },
        ]
    }
];