export const faqs = [
    {
      question: "Tower 1 & 2 Amenities",
      answers:[
        { label: "Tower 1 Lobby", url: 'https://my.matterport.com/show/?m=idQysVDzU7h' },
        { label: "Tower 2 Lobby", url: 'https://my.matterport.com/show/?m=MGHMA5GV2kj' },
        { label: "Level 6 & 7 Amenities", url: 'https://northside-studio.captur3d.io/view/west-side-place/west-side-place-amenity-levels' },
        { label: "Level 11 High Rise Amenities: Yoga Studio, Lounge & Pool", url: 'https://my.matterport.com/show/?m=cCdS8RTAias' },
        { label: "Level 11 High Rise Amenities: Dining, Kitchen & Mahjong Room", url: 'https://my.matterport.com/show/?m=NwbLww1P4D7' },
         ]
    },
    {
      question: "Tower 1 Apartments",
      answers:[
        { label: "Type 02 - 1 Bed 1 Bath", url: 'https://my.matterport.com/show/?m=YotdaWDiMma' },
        { label: "Type 05 - 1 Bed 1 Bath", url: 'https://my.matterport.com/show/?m=p5EGpukxZfM' },
        { label: "Type 03 - 3 Bed 2 Bath", url: 'https://my.matterport.com/show/?m=AJBtZzRDQo2' },
        { label: "Type 04 - 2 Bed 2 Bath", url: 'https://my.matterport.com/show/?m=pjfWnbo1efB' },
        { label: "Type 10 - 2 Bed 2 Bath", url: 'https://my.matterport.com/show/?m=9Mq1QKZ4Uw5' },
        { label: "Type 11 - 2 Bed 2 Bath", url: 'https://my.matterport.com/show/?m=YmLFEmh35bP' },
        { label: "Type 12 - 2 Bed 2 Bath", url: 'https://my.matterport.com/show/?m=bCvrtK2LZMf' },
    ]
    },
    {
      question: "Tower 2 Apartments",
      answers:[
        { label: "Type 01 - 2 Bed 1 Bath", url: 'https://my.matterport.com/show/?m=VFpbUNhsDZB' },
        { label: "Type 02 - 2 Bed 1 Bath", url: 'https://my.matterport.com/show/?m=SmV6MTVcht8' },
        { label: "Type 03 - 1 Bed 1 Bath + Study", url: 'https://my.matterport.com/show/?m=YTKkVkoGrgY' },
        { label: "Type 04 - 1 Bed 1 Bath + Study", url: 'https://my.matterport.com/show/?m=RBMxm7Y9dmS' },
        { label: "Type 06 - 1 Bed + Study", url: 'https://my.matterport.com/show/?m=FR7cSgTND2z' },
        { label: "Type 07 - 1 Bed 1 Bath", url: 'https://my.matterport.com/show/?m=H8rZbaT4hMW' },
        { label: "Type 09 - 2 Bed 2 Bath", url: 'https://my.matterport.com/show/?m=jGWtCSf56aw' },
        { label: "Type 13 - 2 Bed 2 Bath", url: 'https://my.matterport.com/show/?m=UuWt6v8ywah' },
        ]
    },
    {
        question: "Tower 3 & 4 Amenities",
        answers:[
            { label: "Tower 3 Lobby" , url: 'https://my.matterport.com/show/?m=7tzqftpaqrm' },
            { label: "Tower 4 Lobby", url: 'https://my.matterport.com/show/?m=D6aR8XKtEmy' },
            { label: "Level 9 Amenities", url: 'https://my.matterport.com/show/?m=99yUY8bUqbj' },
            { label: "Level 10 Amenities", url: 'https://my.matterport.com/show/?m=qvY6L65yAn2' },
           ]
        },
      {
        question: "Tower 3 Apartments",
        answers:[
            { label: "3401C - 2 Bed 1 Bath", url: 'https://my.matterport.com/show/?m=Z5NQhB8uFbo' },
            { label: "3603C - 1 Bed 1 Bath + Study", url: 'https://my.matterport.com/show/?m=hZ4fvRMT3LZ' },
            { label: "3604C - 3 Bed 2 Bath", url: 'https://my.matterport.com/show/?m=DarUXCqkFDX' },
            { label: "3605C - 2 Bed 2 Bath", url: 'https://my.matterport.com/show/?m=TYyeQ1jAaRX' },
            { label: "3410C - 1 Bed 1 Bath + Study", url: 'https://my.matterport.com/show/?m=wfmjEM5DzK6' },
            ]
        },
      {
        question: "Tower 4 Apartments",
        answers:[
            { label: "3401D - 2 Bed 1 Bath + Study", url: 'https://my.matterport.com/show/?m=nDk2UYqxSLt' },
            { label: "3403D - 1 Bed 1 Bath + Study", url: 'https://my.matterport.com/show/?m=c9gzK4E6Vkv' },
            { label: "3408D - 2 Bed 2 Bath", url: 'https://my.matterport.com/show/?m=DjiRUnogaLo' },
            { label: "3410D - 2 Bed 2 Bath", url: 'https://my.matterport.com/show/?m=fuei2xUodzJ' },
            { label: "3411D - 1 Bed 1 Bath + Study", url: 'https://my.matterport.com/show/?m=Pkca9RU68xD' },
        ]
    },
  ];