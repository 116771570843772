import React, { useState } from "react";
import AccordianItem from "./AccordianItem";
import { faqs } from './data';
import './accordian.scss';

const AccordianContainer = ({ onUrlChange }) => {
    const [clicked, setClicked] = useState(0);

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked(0);
        }
        setClicked(index);
    };

    return (
        <ul className="accordion">
            {faqs.map((faq, index) => (
                <AccordianItem
                    key={index}
                    faq={faq}
                    onToggle={() => handleToggle(index)}
                    active={clicked === index}
                    onUrlChange={(url) => onUrlChange(url)}
                />
            ))}
    </ul>
    )
}

export default AccordianContainer;