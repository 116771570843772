
export const apartments_T1T2_1 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_1_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_2_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/apartments/T1&T2//gallery_apartments_page1_image_3_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_4_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_5_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_6_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_7_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_8_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_9_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_10_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_11_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_12_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page1_image_12_enlarge.jpg' },
];

export const apartments_T1T2_2 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_1_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_2_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/apartments/T1&T2//gallery_apartments_page2_image_3_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_4_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_5_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_6_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_7_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_8_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_9_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_10_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_11_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_12_thumb.jpg', href: '/img/gallery/apartments/T1&T2/gallery_apartments_page2_image_12_enlarge.jpg' },
];

export const apartments_T3T4_1 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_1_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_2_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/apartments/T3&T4//gallery_apartments_page1_image_3_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_4_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_5_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_6_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_7_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_8_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_9_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_10_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_11_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_12_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page1_image_12_enlarge.jpg' },
];

export const apartments_T3T4_2 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_1_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_2_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/apartments/T3&T4//gallery_apartments_page2_image_3_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_4_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_5_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_6_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_7_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_8_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_9_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_10_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_11_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_12_thumb.jpg', href: '/img/gallery/apartments/T3&T4/gallery_apartments_page2_image_12_enlarge.jpg' },
];

export const amenities_T1T2_1 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_1_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_2_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/amenities/T1&T2//gallery_amenities_page1_image_3_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_4_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_5_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_6_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_7_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_8_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_9_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_10_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_11_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_12_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page1_image_12_enlarge.jpg' },
];

export const amenities_T1T2_2 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_1_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_2_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/amenities/T1&T2//gallery_amenities_page2_image_3_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_4_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_5_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_6_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_6_enlarge.jpg' },
	//{ title: '7', left: '965px', top: '340px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_7_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_7_enlarge.jpg' },
	//{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_8_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_8_enlarge.jpg' },
	//{ title: '9', left: '65px', top: '665px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_9_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_9_enlarge.jpg' },
	//{ title: '10', left: '515px', top: '665px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_10_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_10_enlarge.jpg' },
	//{ title: '11', left: '965px', top: '665px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_11_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_11_enlarge.jpg' },
	//{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_12_thumb.jpg', href: '/img/gallery/amenities/T1&T2/gallery_amenities_page2_image_12_enlarge.jpg' },
];

export const amenities_T3T4_1 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_1_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_2_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/amenities/T3&T4//gallery_amenities_page1_image_3_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_4_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_5_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_6_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_7_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_8_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_9_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_10_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_11_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_12_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page1_image_12_enlarge.jpg' },
];

export const amenities_T3T4_2 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_1_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_2_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/amenities/T3&T4//gallery_amenities_page2_image_3_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_4_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_5_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_6_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_7_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_8_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_9_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_10_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_11_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_12_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page2_image_12_enlarge.jpg' },
];

export const amenities_T3T4_3 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_1_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_2_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/amenities/T3&T4//gallery_amenities_page3_image_3_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_4_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_4_enlarge.jpg' },
	//{ title: '5', left: '65px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_5_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_5_enlarge.jpg' },
	//{ title: '6', left: '515px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_6_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_6_enlarge.jpg' },
	//{ title: '7', left: '965px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_7_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_7_enlarge.jpg' },
	//{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_8_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_8_enlarge.jpg' },
	//{ title: '9', left: '65px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_9_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_9_enlarge.jpg' },
	//{ title: '10', left: '515px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_10_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_10_enlarge.jpg' },
	//{ title: '11', left: '965px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_11_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_11_enlarge.jpg' },
	//{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_12_thumb.jpg', href: '/img/gallery/amenities/T3&T4/gallery_amenities_page3_image_12_enlarge.jpg' },
];

export const precincts1 = [
    { title: '1', left: '65px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page1_image_1_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page1_image_2_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page1_image_3_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page1_image_4_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page1_image_5_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page1_image_6_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page1_image_7_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page1_image_8_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page1_image_9_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page1_image_10_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page1_image_11_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page1_image_12_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_12_enlarge.jpg' },

];

export const precincts2 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page2_image_1_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page2_image_2_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page2_image_3_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page2_image_4_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page2_image_5_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page2_image_6_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_6_enlarge.jpg' },
	//{ title: '7', left: '965px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page2_image_7_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_7_enlarge.jpg' },
	//{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page2_image_8_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_8_enlarge.jpg' },
	//{ title: '9', left: '65px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page2_image_9_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_9_enlarge.jpg' },
	//{ title: '10', left: '515px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page2_image_10_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_10_enlarge.jpg' },
	//{ title: '11', left: '965px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page2_image_11_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_11_enlarge.jpg' },
	//{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page2_image_12_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_12_enlarge.jpg' },
];