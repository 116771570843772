import React, { useState } from "react";
import './accordian.scss';

const AccordianSubItem = ({ answer, handleSelect, selected, active, index }) => {
    const [hover, setHover] = useState(false);
    return (
        <li className={active ? 'display' : 'none' } onClick={() => handleSelect(answer.url, index)} style={{ listStyleType: 'disc', color: selected === index || hover ? '#fff' : '#d8c448' }} onMouseEnter={() => setHover(true)} onMouseOut={() => setHover(false)}>
            <div className={`answer_wrapper ${active ? "open" : ""}`}>
            <div className={selected === index ? "active-sub-text" : "inactive-sub-text"}>{answer.label}</div>    
            </div>
        </li>
    )
}

export default AccordianSubItem;