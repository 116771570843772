import { combineReducers } from 'redux';
import precinctsReducer from '../routes/precincts/index';
import featuresReducer from '../routes/features';
import majorProjectsReducer from '../routes/majorProjects/';
import aerialMapReducer from '../routes/map';
import residencesReducer from '../routes/residences/';
import amenitiesReducer from '../routes/amenities/';
import enlargedReducer from '../shared/enlarged/';
import overlayReducer from '../app/components/overlay';


export default combineReducers( {
  precincts: precinctsReducer,
  features: featuresReducer,
  majorProjects: majorProjectsReducer,
  aerialMap: aerialMapReducer,
  residences: residencesReducer,
  amenities: amenitiesReducer,
  enlarged: enlargedReducer,
  overlay: overlayReducer,

} );