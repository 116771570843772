
import React from 'react';
import { data } from './dataAmenities';
import ConnectButtonAmenitiesLevel from './ConnectButtonAmenitiesLevel';
import ConnectButtonFloorPlansLevelsDragger from './ConnectButtonAmenitiesLevelsDragger';


export default function ViewAmenitiesUI ( { id } ) {

    const getButtons = () => {
        const btns = [];
        let levelData;
        for (let i = 0; i < data[id].levels.length; i++) { 
            levelData = data[id].levels[i];
            btns.push(
                <li key={ i }>
                    <ConnectButtonAmenitiesLevel levelData={ levelData } />
                </li>
            );
        }
        return btns;
    }

    return (
        <div className='amenities--ui'>
            <ConnectButtonFloorPlansLevelsDragger id={id} />
            <ul>
                { getButtons() }
            </ul>
        </div>
    );

}