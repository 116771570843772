import React from "react";
import AccordianContainer from "../accordian/AccordianContainer";
import './sideMenu.scss';

const SideMenu = ({ onUrlChange }) => {
    return (
        <div className='side-menu'>
            <AccordianContainer onUrlChange={(url) => onUrlChange(url)} />
        </div>
    )
}

export default SideMenu;