import React from 'react'
import { connect } from 'react-redux'


function ViewPrecinct ( { precinct, currPrecinct } ) {
  
  const precinctStripped = precinct.replace(/\s/g, '-').replace(/’/g, '').toLowerCase(); //repace spaces with - and remove grammar
  const iconSrc = './img/masterplan-precincts/frg--' + precinctStripped + '.jpg';
  console.log(precinctStripped);
  const display = precinct === currPrecinct ? 'block' : 'none'; //only if it's the currently active precinct

  return (
    <img className='shared--img-fill' style={ { display: display } } src={ iconSrc } width='1920' height='1080' alt='precinct icon' />
  );

}


const mapStateToProps = (state) => ({
  currPrecinct: state.precincts.currPrecinct
})

export default connect(mapStateToProps)(ViewPrecinct);