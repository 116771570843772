import React from 'react'
import { connect } from 'react-redux';
import { selectPrecinct } from './index';
import './precincts.scss';


function ButtonPrecinct ( { precinct, currPrecinct, selectPrecinct } ) {
  
  const precinctStripped = precinct.replace(/\s/g, '-').replace(/’/g, '').toLowerCase(); //repace spaces with - and remove grammar
  const iconSrc = './img/masterplan-precincts/icon--' + precinctStripped + '.png';
  
  return (
    <button 
      className={ precinct === currPrecinct ? 'btn__active' : 'btn' } 
      onClick={ () => selectPrecinct(precinct) } 
    >
      {/* <img src={ iconSrc } width='29' height='29' alt='precinct icon' /> */}
      <img src={ iconSrc } width='36.25' height='36.25' alt='precinct icon' />
      { precinct }
    </button>
  );
}


const mapStateToProps = (state) => ({
  currPrecinct: state.precincts.currPrecinct
});

const mapDispatchToProps = (dispatch) => ({
  selectPrecinct: (currPrecinct) => dispatch(selectPrecinct(currPrecinct))
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonPrecinct);