export const data = {
    rotationHotspots: {
        0: [
            { title: 'central-laneway', left: '874px', top: '913px', align: 'top-right', src: './img/precincts/central-laneway-tmb.jpg', href: './img/precincts/central-laneway-enlarge.jpg' },
            { title: 'dorsett-hotel',  left: '806px', top: '896px', align: 'top-right', src: './img/precincts/dorsett-hotel-tmb.jpg', href: '/img/precincts/dorsett-hotel-enlarge.jpg' },
            { title: 'the-ritz-carlton-hotel', left: '1067px', top: '308px', src: './img/precincts/the-ritz-carlton-hotel-tmb.jpg', href: '/img/precincts/the-ritz-carlton-hotel-enlarge.jpg' },
            { title: 'lv-79-sky-lobby', left: '1066px', top: '166px', src: './img/precincts/lv-79-sky-lobby-tmb.jpg', href: '/img/precincts/lv-79-sky-lobby-enlarge.jpg' },
            { title: 'retail-arcade-1', left: '1166px', top: '872px', align: 'top-right', src: './img/precincts/retail-arcade-1-tmb.jpg', href: '/img/precincts/retail-arcade-1-enlarge.jpg' },         ],
        18: [
            { title: 'central-laneway', left: '1023px', top: '871px', align: 'top-right', src: './img/precincts/central-laneway-tmb.jpg', href: './img/precincts/central-laneway-enlarge.jpg' },
            { title: 'the-ritz-carlton-hotel', left: '906px', top: '351px', src: './img/precincts/the-ritz-carlton-hotel-tmb.jpg', href: '/img/precincts/the-ritz-carlton-hotel-enlarge.jpg' },
            { title: 'lv-79-sky-lobby', left: '905px', top: '194px', src: './img/precincts/lv-79-sky-lobby-tmb.jpg', href: '/img/precincts/lv-79-sky-lobby-enlarge.jpg' },
            { title: 'lv-79-sky-lobby', left: '905px', top: '194px', src: './img/precincts/lv-79-sky-lobby-tmb.jpg', href: '/img/precincts/lv-79-sky-lobby-enlarge.jpg' },
            { title: 'retail-arcade-1', left: '732px', top: '906px', align: 'top-right', src: './img/precincts/retail-arcade-1-tmb.jpg', href: '/img/precincts/retail-arcade-1-enlarge.jpg' },
        ],
        36: [
            { title: 'central-laneway', left: '885px', top: '895px', align: 'top-right', src: './img/precincts/central-laneway-tmb.jpg', href: './img/precincts/central-laneway-enlarge.jpg' },
            { title: 'dorsett-hotel',  left: '1172px', top: '820px', align: 'top-right', src: './img/precincts/dorsett-hotel-tmb.jpg', href: '/img/precincts/dorsett-hotel-enlarge.jpg' },
            { title: 'the-ritz-carlton-hotel', left: '801px', top: '322px', src: './img/precincts/the-ritz-carlton-hotel-tmb.jpg', href: '/img/precincts/the-ritz-carlton-hotel-enlarge.jpg' },
            { title: 'lv-79-sky-lobby', left: '784px', top: '173px', src: './img/precincts/lv-79-sky-lobby-tmb.jpg', href: '/img/precincts/lv-79-sky-lobby-enlarge.jpg' },
            { title: 'future-retail-arcade-2', left: '1172px', top: '990px', align: 'top-right', src: './img/precincts/future-retail-arcade-2-tmb.jpg', href: '/img/precincts/future-retail-arcade-2-enlarge.jpg' },
        ],
        54: [
            { title: 'central-laneway', left: '1020px', top: '922px', align: 'top-right', src: './img/precincts/central-laneway-tmb.jpg', href: './img/precincts/central-laneway-enlarge.jpg' },
            { title: 'dorsett-hotel', left: '915px', top: '1013px', align: 'top-right', src: './img/precincts/dorsett-hotel-tmb.jpg', href: '/img/precincts/dorsett-hotel-enlarge.jpg' },
            { title: 'the-ritz-carlton-hotel', left: '945px', top: '280px', src: './img/precincts/the-ritz-carlton-hotel-tmb.jpg', href: '/img/precincts/the-ritz-carlton-hotel-enlarge.jpg' },
            { title: 'lv-79-sky-lobby', left: '956px', top: '149px', src: './img/precincts/lv-79-sky-lobby-tmb.jpg', href: '/img/precincts/lv-79-sky-lobby-enlarge.jpg' },
            { title: 'future-retail-arcade-2', left: '727px', top: '1003px', align: 'top-right', src: './img/precincts/future-retail-arcade-2-tmb.jpg', href: '/img/precincts/future-retail-arcade-2-enlarge.jpg' },
        ]
    }
}