import React, { useEffect, useRef, useState } from "react";
import { utilAnimate } from "../../util/utilAnimate";
import './ViewVirtualTour.scss';
import SideMenu from "./sideMenu/SideMenu";
import AccordianContainer from "./accordian/AccordianContainer";

const ViewVirtualTour = () => {
    const containerRef = useRef(null);
    const [iFrame, setIframe] = useState(null);
    useEffect(() => {
        utilAnimate.fadeIn(containerRef.current);
    }, []);

    const handleUrlChange = (url) => {
        setIframe(url);
    };

    return (
        <div ref={containerRef} className="virtual-tour" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <iframe src={iFrame} className="iframe" />
            <SideMenu onUrlChange={(url) => handleUrlChange(url)} />
        </div>
    )
}

export default ViewVirtualTour;