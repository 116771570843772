
import ViewHome from './home/ViewHome';
// import ViewGallery from './gallery/ViewGallery'; //
import ViewMovies from './movies/ViewMovies';
import ViewMoreMovies from './movies/ViewMoreMovies';
import ViewPrecincts from './precincts/ViewPrecincts';
import ViewFeatures from './features/ViewFeatures';
import ViewLocationAerial from './location-aerial/ViewLocationAerial';
import ViewMap from './map/ViewMap';
import ViewAmenities from './amenities/ViewAmenities';
import ViewResidences from './residences/ViewResidences';
// import ConnectViewGallery from './gallery/ConnectViewGallery';
import { apartments_T1T2_1, apartments_T1T2_2, apartments_T3T4_1, apartments_T3T4_2, amenities_T1T2_1, amenities_T1T2_2, amenities_T3T4_1, amenities_T3T4_2, amenities_T3T4_3, precincts1, precincts2 } from './gallery/data/gallery';
import { data as amenities } from './amenities/dataAmenities';
import ViewVirtualTour from './tour/ViewVirtualTour';
import ViewGallery from './gallery/ViewGallery';

export const dataRoutes = [
    {
        title: 'Location',
        routes: [
            {
                title: 'Aerial',
                path: '/location-aerial',
                component: ViewLocationAerial
            },
            {
                title: 'Map',
                path: '/location-map',
                component: ViewMap
            }
        ]
    },
    
    {
        title: 'Precinct',
        path: '/precincts',
        component: ViewFeatures,
    },
    {
        title: 'Amenities',
        routes: [
            {
                title: 'Tower 1 & 2',
                path: '/amenities-1',
                component: ViewAmenities,
                data: amenities[0], 
            },
            {
                title: 'Tower 3 & 4',
                path: '/amenities-2',
                component: ViewAmenities,
                data: amenities[1], 
            },
        ]
    },
    {
        title: 'Apartments',
        path: '/apartments',
        component: ViewResidences ,
 
    },

    {
        title: 'Virtual Tours',
        path: '/virtual-tours',
        component: ViewVirtualTour
    },
    {
        title: 'Gallery',
        routes: [
            {
                title: 'T1 & 2 Apartments',
                path: '/gallery-apartments-T1&T2',
                component: ViewGallery,
                data: [apartments_T1T2_1, apartments_T1T2_2], 
            },
            {
                title: 'T3 & T4 Apartments',
                path: '/gallery-apartments-T3&T4',
                component: ViewGallery,
                data: [apartments_T3T4_1, apartments_T3T4_2], 
            },
            {
                title: 'T1 & T2 Amenities',
                path: '/gallery-amenities-T1&T2',
                component: ViewGallery,
                data: [amenities_T1T2_1, amenities_T1T2_2], 
            },
            {
                title: 'T3 & T4 Amenities',
                path: '/gallery-amenities-T3&T4',
                component: ViewGallery,
                data: [amenities_T3T4_1, amenities_T3T4_2, amenities_T3T4_3], 
            },
            {
                title: 'Precinct',
                path: '/gallery-precincts',
                component: ViewGallery,
                data: [precincts1, precincts2],  
            },
        ]
    },
    {
        title: 'Home',
        path: '/',
        component: ViewHome
    }
];