import React, { useEffect } from 'react';
import LoadContainer from '../../shared/LoadContainer';
import ViewAmenitiesUI from './ViewAmenitiesUI';
import ViewAmenitiesLevel from './ConnectViewAmenitiesLevel';
import './stylesAmenities.scss';


export default function ViewAmenities ({ data }) {
    const getLevels = () => {
        const levels = [];
        let levelData;
        for (let i = 0; i < data.levels.length; i++) { 
            levelData = data.levels[i];
            levels.push(
                <ViewAmenitiesLevel key={ i } levelData={ levelData } />
            );
        }
        return levels;
    }

    return (
        <LoadContainer>
            <div className='amenities--levels'>
                { getLevels() }
            </div>
            <ViewAmenitiesUI id={data.id} />
        </LoadContainer>
    );

}