import React, { useRef } from 'react';
import { selectEnlarged } from '../enlarged';
import { connect } from 'react-redux';
import { utilAnimate } from '../../util/utilAnimate';
import './stylesPhotoHotspot.scss'


function ButtonPhotoHotspot ( { data, onSelected, selectEnlarged } ) {
  
  const imgRef = useRef(null);
  
  const onMouseEnter = (event) => {
    onSelected(event.currentTarget.parentNode); //to ensure it appears over the other hotspots
    imgRef.current.style.zIndex = 3;
    utilAnimate.fadeIn(imgRef.current);
  }

  const onClick = () => {
    if (data.href !== undefined) {
      selectEnlarged( { srcList: [data.href] } );
    }
  }

  const onMouseLeave = () => {
    utilAnimate.fadeOut(imgRef.current);
  }

  return (
    <div className='amenities--hotspot' style={ { left: data.left, top: data.top } }>
      <button className='amenities--hotspot__btn' onClick={ onClick } onMouseEnter={ onMouseEnter } onMouseLeave={ onMouseLeave } ></button>
      <img className={ 'amenities--hotspot__tmb ' + data.align || '' } src={ data.src } ref={ imgRef } alt={ data.title } />
    </div>
  );

}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  selectEnlarged: (data) => dispatch(selectEnlarged(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonPhotoHotspot);