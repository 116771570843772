import React, { useState } from "react";
import { connect } from 'react-redux'
import { toggleDayNight } from ".";
import ButtonResidencesDayNight from './ButtonResidencesDayNight';
const ConnectViewResidencesDayNight = ({ currRotation, toggleDayNight, Day }) => {
    console.log(Day);
    const renderTitle = () => {
        if (currRotation === 0) {
            return 'Tower 2'
        } else if (currRotation === 18) {
            return 'Tower 1'
        } else if (currRotation === 36) {
            return 'Tower 4'
        } else if (currRotation === 54) {
            return 'Tower 3'
        }

        return null
    }
    return renderTitle() && (
            <div className="residences--building-container">
                <div className="residences--building-Day-Night-container">               
                    <ButtonResidencesDayNight data={{ title: 'Night', top: '-3px', left: '-2px' }} onSelect={() => toggleDayNight('Night')} selected={Day === 'Night'}/> 
                    <ButtonResidencesDayNight data={{ title: 'Day', top: '-3px', left: '58px' }} onSelect={() => toggleDayNight('Day')} selected={Day === 'Day'}/> 
                </div>
            </div> );
}

//  return renderTitle() && (
//            <div className="residences--building-container">
//                <h3 className="residences--building__title" style={{ top: '0px', left: '0px' }}>{renderTitle()}</h3>
//                <div className="residences--building-Day-Night-container">
//                    <ButtonResidencesDayNight data={{ title: 'Day', top: '-2px', left: '-10px' }} onSelect={() => toggleDayNight('Day')}/> 
//                    <ButtonResidencesDayNight data={{ title: 'Night', top: '-2px', left: '50px' }} onSelect={() => toggleDayNight('Night')}/> 

const mapStateToProps = (state) => ({
    currRotation: state.residences.currRotation,
    Day: state.residences.Day,
  })
  
  const mapDispatchToProps = (dispatch) => ({
    toggleDayNight: (Day) => dispatch(toggleDayNight(Day)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(ConnectViewResidencesDayNight)