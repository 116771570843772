
import React from 'react';
import ConnectViewFeaturesRotation from './ConnectViewFeaturesRotation';
import ConnectViewFeaturesUIRotate from './ConnectViewFeaturesUIRotate';
import LoadContainer from '../../shared/LoadContainer';
import './stylesFeatures.scss';


export default function ViewFeatures () {
    
    const rotations = [];
    for (let i = 0; i <= 72; i++) { 
        rotations.push(
            <ConnectViewFeaturesRotation key={ i } rotation={ i } />
        );
    }

    return (
        <LoadContainer>
            <div className='features--rotations'> 
                { rotations }
            </div>
            <ConnectViewFeaturesUIRotate />
        </LoadContainer>
    );

}